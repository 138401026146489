import { createMetaInfoObject } from '~/lib'

interface MetaTags {
  [key: string]: string
}

export const useDrupalMetaTags = (entity: any) => {
  const isIndexable =
    entity.__typename === 'NodeProtectedPage' || entity.__typename === 'NodeCbzLandingPage' ? false : true

  const tags: MetaTags = {}
  if (entity.entityMetatags) {
    entity.entityMetatags.forEach((tag: any) => {
      tags[tag.key] = tag.value
    })
  }

  useSeoMeta(
    createMetaInfoObject([
      ['title', tags['title'] || undefined],
      ['description', tags['description'] || undefined],
      ['keywords', tags['keywords'] || undefined],
      ['image_src', tags['image_src'] || undefined],
      ['og:title', tags['og:title'] || undefined],
      ['og:description', tags['og:description'] || undefined],
      ['og:image', tags['og:image'] || undefined],
      ['og:site_name', tags['og:site_name'] || undefined],
      ['og:url', tags['canonical'] || undefined],
      ['canonical', tags['canonical'] || undefined],
      ['content-language', tags['content-language'] || undefined],
      ['robots', !isIndexable ? 'noindex, nofollow, noimageindex' : tags['robots'] || undefined],
    ]),
  )

  const config = useRuntimeConfig()

  // Add alternate links.
  const alternateLinks = entity.entityMetatags
    ? entity.entityMetatags
        .filter((tag: any) => tag.key === 'alternate')
        .map((tag: any) => ({
          rel: tag.value.rel,
          hreflang: tag.value.hreflang,
          href: `${config.public.frontendBaseUrl}${tag.value.href}`,
        }))
    : []
  useHead({
    link: alternateLinks,
  })
}
